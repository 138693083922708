import React from 'react'
import * as styles from './socialbutton.module.scss'

export default ({icon, text, onClick, style}) => {
    return(
        <div onClick={onClick} className={styles.container} style={style}>
            <div className={styles.icon}>
                {icon}
            </div>
            <span>{text}</span>
        </div>
    )
}