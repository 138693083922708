import { ErrorMessage, Field } from 'formik';
import React from 'react';
import * as styles from './forminput.module.scss';

export default ({type, name, error, label, info, placeholder, to}) => {
        return(
            <>
            <div className={type === 'checkbox' ? styles.checkboxDiv : styles.textDiv}>

            <label htmlFor={name} className={styles.label}>
            {type === 'checkbox' && to ?
              <a href={to} target={'_blank'}>
                {label}
              </a> : label}</label>
              <Field placeholder={placeholder} component={type === 'textarea' ? 'textarea' : 'input'} type={type} name={name} className={`${styles.field} ${error ? styles.errorField : undefined}`}/>
            </div>
              <ErrorMessage name={name} component="div" className={styles.errorMessage} />
              {info ? <div className={styles.info}>
                <span className={info.onClick ? styles.link : undefined} onClick={info.onClick ? info.onClick : null}>{info.text}</span>
              </div> : null}
          </>
        )
}