import React from 'react'
import { useSelector } from 'react-redux';
import SyncLoader from "react-spinners/SyncLoader";

export default () => {
  let show = useSelector(state => state.UserSlice.loader)
    return(
      show ?
        <>
        <div style={{position: 'fixed', top:0, bottom:0, left:0, right:0, zIndex: 3, display: 'flex', alignItems: 'center', justifyContent: 'center',}} >
        <SyncLoader
                size={20}
                //size={"150px"} this also works
                color={"#ffffff"}
                loading={true}
                    />
        </div>
        <div style={{position: 'fixed', top:0, bottom:0, left:0, right:0, zIndex: 2, backgroundColor: 'black', opacity: 0.5}} />
      </> : null
    )
}