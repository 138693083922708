


export function push_event_to_datalayer(eventName){
  if (typeof window !== 'undefined') {
    if (window.dataLayer){
      window.dataLayer.push({event: eventName});
    }
  }
  return null
}