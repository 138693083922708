import { navigate } from "gatsby"
import React from "react"
import { useDispatch } from "react-redux"
import * as Yup from 'yup'
import Firebase from '../../../firebase/index'
import { setLoginModal, setUser } from "../../../slices/user-slice"
import Form from '../../Common/Form/Form'

export default _ => {
  let dispatch = useDispatch()
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid e-mail address.')
      .required('E-mail is required'),
    password: Yup.string()
      .required('Password is required'),
  });

  async function onSubmit(values){
    let response = await Firebase.login(values.email, values.password)
    if(response){
      dispatch(setUser(true))
    }

    return
  }

  function resetPassword(){
    dispatch(setLoginModal(false))
    navigate('/reset-password')
  }

  return (
    <>
    <Form validationSchema={validationSchema} onSubmit={(values) => onSubmit(values)} initialValues={{
          email: "",
          password: "",
        }} fields={[
            {
                placeholder: 'example@mail.com',
                label: "E-mail",
                type: 'email',
                name: 'email'
            },
            {
                placeholder: 'Your Password',
                label: "Password",
                type: 'password',
                name: 'password',
                info: {
                    text: `Don't remember your password?`,
                    onClick: resetPassword
                }
            }
        ]} 
        submitButtonText={'Log in'}
        />
    </>
  )
}