import { createSlice } from "@reduxjs/toolkit";
import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuidv4 } from "uuid";

const initialState = () => {
  return{
    name: '',
    animated: false,
    uuid: uuidv4(),
    duration: 10000,
    currentTime: 0,
    layerZoom: 0.1,
    scene: null,
    playing: false,
    errorModal: false,
    recording: {
      status: false,
      format: null,
      progress: 0,
      text: '',
    },
    dimensions: {
      modal: false,
      width: 1920,
      height: 1080,
    },
    background: {
      modal: false,
      priority: "solid",
      solid: "rgba(255, 255, 255, 1)",
      linear: {
        deg: 180,
        color1: "#4e54c8",
        color2: "#8f94fb",
        percent1: 0,
        percent2: 100,
      },
      image: "https://firebasestorage.googleapis.com/v0/b/promopreview/o/collections%2Fgradients%2Fabstract_gradient_9.jpg?alt=media&token=ad57d48a-c783-4e04-9cd6-fde81b3a9d3f",
    },
    currentItem: {
      class: 'camera',
      id: undefined,
    },
    currentTimestamp: {
      class: '',
      id: '',
      key: '',
      index: ''
    },
    editor: {
      show: true,
      side: 'left'
    },
    images: [],
    texts: [],
    devices: [],
    camera: {
      name: 'Camera',
      class: 'camera',
      animationTool: false,
      zoom: 1,
      position: {x: 0, y:0, z: 15},
      animations: {
        position: [],
        zoom: [],
      },
    },
    plane: {
      name: 'Plane',
      class: 'plane',
      height: -2,
      blur: 1,
      opacity: 1,
      active: false,
      time: {
        start: 0,
        end: 5000
      }
    },
    lottieCanvas: {
      dimensions: {width: 1, height:1},
      animations: {},
      fonts: {}
    }
  }
} 

export const threeSlice = createSlice({
  name: "threeSlice",
  initialState: initialState(),
  reducers: {
    setCanvas: (state, action) => {
      let newState = {...initialState(), ...action.payload}
      // Set duration
      let totalDuration = [...newState.devices, ...newState.images, ...newState.texts, newState.plane.active ? newState.plane : {}].reduce((acc, item) => {
        if(item.time && item.time.end > acc){
          acc = item.time.end
        }
        return acc
      }, 0)
      // Check if there are any animations present
      let animated = [...newState.devices, ...newState.images, ...newState.texts, newState.camera].some((item) => {
        if(item.animations){
          return Object.keys(item.animations).some((key) => item.animations[key].length)
        }
        return false
      })

      newState.animated = animated
      newState.duration = totalDuration > 0 ? totalDuration : 5000

      // Fix background if old type
      if(newState.background.value){
        newState.background = {...newState.background.value, modal: false}
      }
      return newState
    },
    resetCanvas: (state, action) => {
      return {...initialState(), scene: state.scene}
    },
    changeLayerZoom: (state, action) => {
      state.layerZoom = action.payload
    },
    changeTimeStamp: (state, action) => {
      let item
      let itemClass = action.payload.item.class
      let {timestamp, param, value} = action.payload

      if(itemClass === 'camera' || itemClass === 'background'){
        item = state[itemClass]
        if(timestamp === 0 || timestamp === item.timestamps.length - 1){
          return
        }
      }
      else {
        let itemId = action.payload.item.id
        item = state[itemClass][itemId]
      }
      item.timestamps[timestamp][param] = value
    },
    setCurrentTime: (state, action) => {
      state.currentTime = action.payload
      state.currentItem.timestamp = undefined
    },
    setDragging: (state, action) => {
      state.dragging = action.payload;
    },
    setOrbiting: (state, action) => {
      state.orbiting = action.payload;
    },
    setScene: (state, action) => {
      state.scene = action.payload;
    },
    setSpecificClassParam: (state, action) => {
      if(action.payload.id !== undefined){
        state[action.payload.class][action.payload.id] = {...state[action.payload.class][action.payload.id], ...action.payload.value}
      }
      else {
        state[action.payload.class] = {...state[action.payload.class], ...action.payload.value}
      }
    },
    setClassParam: (state, action) => {
      // Find item
      var item, start

      if(state.currentItem.id !== undefined){
        item = state[state.currentItem.class][state.currentItem.id]
        start = item.time.start
      }
      else {
        item = state[state.currentItem.class]
        start = 0
      }
      // Check if animation exists
      // If yes --> add to animation. If not --> set default param

      if(item.animations && item.animations[action.payload.param]?.length){
        //  If animation exists, add to animation

        // If animation at current Time already exists, edit it
        let exists = item.animations[action.payload.param].find(a => a.time + start === state.currentTime)
        if(exists){
          exists.value = action.payload.value
        }
        // Else create and add a new timestamp
        else{
            item.animations[action.payload.param] = [
              ...item.animations[action.payload.param],
              {
                time: state.currentTime - start,
                easing: 'Linear.None',
                value: action.payload.value
              }
          ].sort((a,b) => a.time - b.time)
        }

      }
      else{
        // Animation doesn't exist, just add to param
        item[action.payload.param] = action.payload.value
      }


      // if(!Object.keys(item).includes(action.payload.param)){
      //   // Param must be inside of checkpoint
      //   let timestamp 
      //   if(state.currentItem.timestamp !== undefined){
      //     timestamp = item.timestamps[state.currentItem.timestamp]
      //   }
      //   else {
      //     timestamp = item.timestamps.findIndex(el => state.currentTime >= el.time)
      //   }
      //   // if(timestamp.time !== state.currentTime){
      //   //   toast.error('Select a checkpoint to make changes')
      //   //   return
      //   // }
      //   timestamp[action.payload.param] = action.payload.value
      // }
      // else {
      //   item[action.payload.param] = action.payload.value
      // }
    },
    removeClass: (state, action) => {
      let acceptedClasses = ["devices", "images", "texts", "plane"];
      if(!acceptedClasses.includes(state.currentItem.class)){
        return
      }
      if(state.currentItem.id !== undefined){
        state[state.currentItem.class][state.currentItem.id] = {}
      }
      else {
        state[state.currentItem.class] = {}
      }
      state.currentItem = {
        class: 'camera',
        id: undefined,
        timestamp: undefined,
      }
        // Set duration to largest value
        let totalDuration = [...state.devices, ...state.images, ...state.texts].reduce((acc, item) => {
          if(item.time && item.time.end > acc){
            acc = item.time.end
          }
          return acc
      }, 0)
      if(totalDuration === 0) totalDuration = 5000
      state.duration = totalDuration
    },
    setEditor: (state, action) => {
      state.editor[action.payload.param] = action.payload.value;
    },
    changeAnimation: (state, action) => {
      state[state.currentItem.class][state.currentItem.id].animation = {...state[state.currentItem.class][state.currentItem.id].animation, ...action.payload}
    },
    changeTextAnimation: (state, action) => {
      let text = state[state.currentItem.class][state.currentItem.id].animation.texts[action.payload.index]
      text[action.payload.param] = action.payload.value
    },
    setCurrentItem: (state, action) => {
      let editorOpen = state.editor.show
      if(state.currentItem.class !== action.payload.class || state.currentItem.id !== action.payload.id ){
        state.currentItem = {class: action.payload.class, id: action.payload.id}
      }
      if(!editorOpen){
        state.editor.show = true
      }
      if(action.payload.time !== undefined){
        state.currentTime = action.payload.time
      }
      state.currentTimestamp = {
        class: '',
        id: '',
        key: '',
        index: ''
      }
      // if(action.payload.class !== state.currentTimestamp.class || action.payload.id !== state.currentTimestamp.id){
      //   state.currentTimestamp = {
      //     class: '',
      //     id: '',
      //     key: '',
      //     index: ''
      //   }
      // }
    },
    setDuration: (state, action) => {
      let oldDuration = state.duration

      // Get new duration difference
      let newDuration = action.payload
      let difference = newDuration / oldDuration
      // Update all animation times proportioanlly
      let animations = state.camera.animations
      Object.keys(animations).forEach(key => {
        animations[key].forEach(el => {
          el.time *= difference
        })
      })

      state.duration = newDuration
      if(newDuration < state.currentTime){
        state.currentTime = newDuration
      }
    },
    togglePlay: (state, action) => {
      state.playing = !state.playing
    },
    addTimestamp: (state, action) => {
      // Find item
      var item
      var exists = false
      if(state.currentItem.id !== undefined){
        item = state[state.currentItem.class][state.currentItem.id]
      }
      else {
        item = state[state.currentItem.class]
      }
      let index = item.timestamps.reduce((acc, el, index) => {
        if(state.currentTime === el.time) exists = true
        if(state.currentTime > el.time){
          acc = index
        }
        return acc
      }, null)
      // If timestamp exists already --> don't add
      if(exists) return

      let newTimestamps = [...item.timestamps]
      if(index !== null){
        newTimestamps.splice(index + 1, 0, {...item.timestamps[index], time: state.currentTime})
        state.currentItem.timestamp = index + 1
      }
      else{
        newTimestamps = [{...item.timestamps[0], time: state.currentTime}, ...newTimestamps]
        state.currentItem.timestamp = 0
      }

      item.timestamps = newTimestamps
    },
    setLottieFont: (state, action) => {
      state.lottieCanvas.fonts = {...state.lottieCanvas.fonts, ...action.payload}
    },
    setLottieAnimation: (state, action) => {
      let {item, width, height} = action.payload
      let animationItem = state.lottieCanvas.animations[`${item.class}-${item.id}`]
      state[item.class][item.id].ratio = width/height
      if(!animationItem){
        state.lottieCanvas.animations = {...state.lottieCanvas.animations, [`${item.class}-${item.id}`]:{
          class: item.class,
          id: item.id,
          width,
          height
        }}
      }
      else {
        animationItem.width = width
        animationItem.height = height
      }
      let canvasDims = {height: 1, width: 1}
      let prevRowLowestY = 0
      let currentRowY = 0
      for(let i=0; i < Object.keys(state.lottieCanvas.animations).length; i++){
        let prevKey = Object.keys(state.lottieCanvas.animations)[i - 1]
        let key = Object.keys(state.lottieCanvas.animations)[i]


        let prevBounds = i !== 0 ? state.lottieCanvas.animations[prevKey].bounds : [0,0,0,0]
        let topLeft, bottomRight

        // If last animation is almost at canvas width
        // Go to next line
        if(prevBounds[2] > canvasDims.width - 1){
          currentRowY = prevRowLowestY + 1
          topLeft = [0, currentRowY]
        }
        else{
          // Else stay on this line and increase canvas width
          topLeft = [prevBounds[2] + 1, currentRowY]
        }
        bottomRight = [topLeft[0] + state.lottieCanvas.animations[key].width, topLeft[1] + state.lottieCanvas.animations[key].height]

        if(bottomRight[1] > prevRowLowestY){
          prevRowLowestY = bottomRight[1]
        }

        state.lottieCanvas.animations[key].bounds = [...topLeft, ...bottomRight]

        if(bottomRight[0] > canvasDims.width){
          canvasDims.width = Math.ceil(bottomRight[0])
        }
        if(bottomRight[1] > canvasDims.height){
          canvasDims.height =  Math.ceil(bottomRight[1])
        }
      }
      state.lottieCanvas.dimensions = {...canvasDims}

    },

    setRecording: (state, action) => {
      if(action.payload.param === 'status'){
        state.currentTime = 0
      }
      if(action.payload.param === 'progress'){
        if(action.payload.value < 0){
          action.payload.value = 0
        }
        else if(action.payload.value > 100){
          action.payload.value = 100
        }
      }
      state.recording[action.payload.param] = action.payload.value
    },
    setDimensionsModal: (state, action) => {
      state.dimensions.modal = action.payload
    },
    setDimensions: (state, action) => {
      state.dimensions.width = action.payload.width
      state.dimensions.height = action.payload.height
    },
    addText: (state, action) => {
      let id = state.texts.length
      let newItem = {
        name: `Text ${id + 1}`,
        text: 'Your Text',
        color: [0, 0, 0],
        scale: 0.1,
        class: 'texts',
        alignment: 'center',
        id: id,
        position: {x:0, y:0, z:0},
        time: {
          start: 0,
          end: state.duration
        },
        font: {
          family: "Poppins",
          style: "300",
          custom: false
        },
        animationTool: false,
        rotation: 0,
        ratio: 1,
        opacity: 1,
        animations: {
          position: [],
          rotation: [],
          scale: [],
          opacity: []
        },
        textAnimation: {
          intro: {
            type: 'None',
            duration: 1000,
            by: 'letters'
          },
          outro: {
            type: 'None',
            duration: 1000,
            by: 'letters'
          },
        },
      }
      state.texts = [...state.texts, newItem]
      state.currentItem = {class: 'texts', id}
    },
    addDevice: (state, action) => {
      let id = state.devices.length
      let newItem = {
        name: `Device ${id + 1}`,
        frame: action.payload,
        color: "rgb(200,200,200)",
        position: {x: 0, y:0, z: 0},
        rotation: {x: 0, y:0, z:0},
        scale: 1,
        time: {
          start: 0,
          end: state.duration
        },
        animationTool: false,
        animations: {
          position: [],
          rotation: [],
          scale: [],
        },
        class: 'devices',
        id: id,
        clay: true,
        screenshot: null,
        mode: 'move',
      }
      state.devices = [...state.devices, newItem]
      state.currentItem = {class: 'devices', id, timestamp: 0}
    },
    addImage: (state, action) => {
      let id = state.images.length
      let newItem = {
        name: `Image ${id + 1}`,
        image: action.payload,
        scale: 0.1,
        class: 'images',
        id: id,
        position: {x:0, y:0, z:0},
        rotation: 0,
        ratio: 1,
        opacity: 1,
        animationTool: false,
        animations: {
          position: [],
          rotation: [],
          scale: [],
          opacity: []
        },
        time: {
          start: 0,
          end: state.duration
        },
      }
      state.images = [...state.images, newItem]
      state.currentItem = {class: 'images', id, timestamp: 0}
    },
    toggleObjectAnimation: (state, action) => {
      let item, start, end
      if(action.payload.id !== undefined){
        item = state[action.payload.class][action.payload.id]
        start = item.time.start
        end = item.time.end
      }
      else {
        item = state[action.payload.class]
        start = 0
        end = state.duration
      }
      if(item.animations[action.payload.animation].length){
        item.animations[action.payload.animation] = []
        state.currentTimestamp = {
          class: '',
          id: '',
          key: '',
          index: ''
        }
      }
      else{
        let time = state.currentTime < start ? 0 : state.currentTime > end ? end - start : state.currentTime - start
        item.animations[action.payload.animation] = [{
          easing: 'Linear.None',
          time,
          value: cloneDeep( item[action.payload.animation] )
        }]
      }
    },
    setObjectAnimationParam: (state, action) => {
      let item
      if(action.payload.id !== undefined){
        item = state[action.payload.class][action.payload.id]
      }
      else {
        item = state[action.payload.class]
      }
      item.animations[action.payload.animation][action.payload.index][action.payload.param] = action.payload.value
      if(action.payload.param === 'time'){
        item.animations[action.payload.animation].sort((a,b) => a.time - b.time)
      }
    },
    setCurrentTimestamp: (state, action) => {
      state.currentTimestamp = action.payload
      state.currentItem = {
        class: action.payload.class,
        id: action.payload.id
      }
    },
    deleteTimestamp: (state, action) => {

      let item
      if(state.currentTimestamp.id !== undefined){
        item = state[state.currentTimestamp.class][state.currentTimestamp.id]
      }
      else {
        item = state[state.currentTimestamp.class]
      }
      // remove from array
      item.animations[state.currentTimestamp.key].splice(state.currentTimestamp.index, 1)

      // set current timestamp to empty
      state.currentTimestamp = {}
    },
    setEasing: (state, action) => {

      let item
      if(state.currentTimestamp.id !== undefined){
        item = state[state.currentTimestamp.class][state.currentTimestamp.id]
      }
      else {
        item = state[state.currentTimestamp.class]
      }
      // set easing
      item.animations[state.currentTimestamp.key][state.currentTimestamp.index].easing = action.payload
    },
    updateTime: (state, action) => {
      let item
      if(action.payload.id !== undefined){
        item = state[action.payload.class][action.payload.id]
      }
      else {
        item = state[action.payload.class]
      }
      // Get previous duration
      let oldDuration = item.time.end - item.time.start

      // if param, change only param
      if(action.payload.param){
        item.time[action.payload.param] = action.payload.value
      }
      // Else change both
      else{
        item.time = action.payload.value
      }

      if(item.animations){
        // Get new duration difference
        let newDuration = item.time.end - item.time.start
        let difference = newDuration / oldDuration
        // Update all animation times proportioanlly
        Object.keys(item.animations).forEach(key => {
          item.animations[key].forEach(el => {
            el.time *= difference
          })
        })
      }
      // Set duration to largest value
      let totalDuration = [...state.devices, ...state.images, ...state.texts, state.plane.active ? state.plane : {}].reduce((acc, item) => {
          if(item.time && item.time.end > acc){
            acc = item.time.end
          }
          return acc
      }, 0)
      state.duration = totalDuration
      if(state.currentTime > totalDuration){
        state.currentTime = totalDuration
      }

    },
    setName: (state, action) => {
      state.name = action.payload
    },
    toggleRotate: (state, action) => {
      state.currentItem.rotate = !state.currentItem.rotate
    },
    cloneItem: (state, action) => {
      let item = state[action.payload.class][action.payload.id]

      if(action.payload.key){
        // Must be a timestamp, clone it to correct key
        let clone = cloneDeep(item.animations[action.payload.key][action.payload.index])
        // Offset time slightly
        clone.time += 10 / state.layerZoom
        // Insert into array
        item.animations[action.payload.key].splice(action.payload.index + 1, 0, clone);
      }
      else{
        // Must be a whole item
        let id = state[action.payload.class].length
        let name = `${item.name} copy`
        let newItem = {
          ...item,
          name,
          id
        }
        state[action.payload.class] = [...state[action.payload.class], newItem]
      }
    },
    setErrorModal: (state, action) => {
      state.errorModal = action.payload
    },
    setBackground: (state, action) => {
      state.background = {...state.background, ...action.payload};
    },
    setAnimated: (state, action) => {
      state.animated = action.payload
    },
  }
});

export const {
  setErrorModal,
  cloneItem,
  setName,
  changeLayerZoom,
  changeTimeStamp,
  setCurrentTime,
  setHover,
  setDragging,
  setOrbiting,
  setClassParam,
  setEditor,
  setCurrentItem,
  removeClass,
  setScene,
  changeAnimation,
  changeTextAnimation,
  setDuration,
  togglePlay,
  addTimestamp,
  deleteTimestamp,
  setLottieAnimation,
  setLottieFont,
  setRecording,
  setSpecificClassParam,
  setDimensionsModal,
  setDimensions,
  addText,
  addDevice,
  addImage,
  toggleObjectAnimation,
  setObjectAnimationParam,
  setCurrentTimestamp,
  updateTime,
  setEasing,
  resetCanvas,
  setCanvas,
  toggleRotate,
  setBackground,
  setAnimated
} = threeSlice.actions;

export default threeSlice.reducer;
