import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import ImagePlaceholder from "../assets/images/screenshot.png";
import { getLargestZedIndex } from "../components/Makers/2D-image/Canvas/Functions";

export const initialState = () => {
  return {
    images: [],
    devices: [],
    texts: [],
    frames: [],
    background: {
      modal: false,
      priority: "solid",
      solid: "#ffffff",
      linear: {
        deg: 180,
        color1: "#4e54c8",
        color2: "#8f94fb",
        percent1: 0,
        percent2: 100,
      },
      image: "https://firebasestorage.googleapis.com/v0/b/promopreview/o/collections%2Fgradients%2Fabstract_gradient_9.jpg?alt=media&token=ad57d48a-c783-4e04-9cd6-fde81b3a9d3f",
    },
    meta: {
      numOfScreenshots: 1,
      gapWidth: 56,
      dimensions: {
        modal: false,
        width: 1242,
        height: 2688,
      }
    },
    selectedId: "",
    active: false,
    stageRef: null,
    uuid: uuidv4(),
    name: null,
    saved: false
  }
} 

export const canvasSlice = createSlice({
  name: "canvasSlice",
  initialState: initialState(),
  reducers: {
    setCanvasStyle: (state, action) => {
      state.images = action.payload.images;
      state.devices = action.payload.devices;
      state.texts = action.payload.texts;
      state.background = action.payload.background;
      state.meta = action.payload.meta;
      state.selectedId = "";
      state.uuid = action.payload.uuid;
      state.name = action.payload.name;
      state.saved = action.payload.saved;
      state.originalTemplateId = action.payload.originalTemplateId
    },
    addClass: (state, action) => {
      let newItem
      let zedIndex = getLargestZedIndex(state) + 1
      if(action.payload === 'devices'){
        let screenshot = ImagePlaceholder;
        let frame = {
          "device": "iPhone 12 Real",
          "frame_size": {
            "width": 0.892,
            "y": 0.023,
            "x": 0.055,
            "height": 0.954
          },
          "children": [
            {
              "color": "graphite",
              "id": "iphone_12_graphite",
              "thumbnail_id": "iphone_12_graphite_thumbnail"
            },
            {
              "color": "black",
              "id": "iphone_12_black",
              "thumbnail_id": "iphone_12_black_thumbnail"
            },
            {
              "color": "blue",
              "id": "iphone_12_blue",
              "thumbnail_id": "iphone_12_blue_thumbnail"
            },
            {
              "color": "silver",
              "id": "iphone_12_silver",
              "thumbnail_id": "iphone_12_silver_thumbnail"
            },
            {
              "color": "white",
              "id": "iphone_12_white",
              "thumbnail_id": "iphone_12_white_thumbnail"
            },
            {
              "color": "red",
              "id": "iphone_12_red",
              "thumbnail_id": "iphone_12_red_thumbnail"
            },
            {
              "color": "green",
              "id": "iphone_12_green",
              "thumbnail_id": "iphone_12_green_thumbnail"
            },
            {
              "color": "gold",
              "id": "iphone_12_gold",
              "thumbnail_id": "iphone_12_gold_thumbnail"
            }
          ],
          "proportion": 2.0226519337016575
        }
        newItem = {
          x: 0,
          y: 0,
          width: 300,
          name: "object",
          screenshot_url: screenshot,
          id: `device${state.devices.length}`,
          frame: frame,
          shadow: false,
          zedIndex
        }
      }
      else if(action.payload === 'images'){
        newItem = {
          x: 0,
          y: 0,
          height: 200,
          width: 200,
          image_url: "https://firebasestorage.googleapis.com/v0/b/promopreview/o/collections%2Fbadges%2Fdownload_on_the_app_store_badge_us_uk_rgb_blk_092917.png?alt=media&token=7f6f0b82-f9f2-4604-8ab4-7848acb2f80a",
          name: "object",
          id: `image${state.images.length}`,
          zedIndex
        }
      }
      else if(action.payload === 'texts'){
        let width = state.meta.dimensions.width
        let fontScale = width/300
        newItem = {
          x: 0,
          y: 0,
          name: "object",
          text: "New Text",
          id: `text${state.texts.length}`,
          font: {
            family: "Poppins",
            style: "300",
            custom: false
          },
          scale: { x: fontScale, y: fontScale },
          align: "left",
          fill: "black",
          zedIndex
        }
      }
      state[action.payload] = [...state[action.payload], newItem];
    },
    removeClass: (state, action) => {
      state.selectedId = '';
      state[action.payload.class][action.payload.id] = {}
    },
    setClassParam: (state, action) => {
      state[action.payload.class][action.payload.id][action.payload.param] = action.payload.value
    },
    setImages: (state, action) => {
      state.images = action.payload;
    },
    setDevices: (state, action) => {
      state.devices = action.payload;
    },
    setTexts: (state, action) => {
      state.texts = action.payload;
    },
    setFrames: (state, action) => {
      state.frames = action.payload;
    },
    setBackground: (state, action) => {
      state.background = {...state.background, ...action.payload};
    },
    setMeta: (state, action) => {
      state.meta = action.payload;
    },
    setNumberOfScreenshots: (state, action) => {
      state.meta.numOfScreenshots = action.payload;
    },
    setDimensions: (state, action) => {
      state.meta.dimensions = {...state.meta.dimensions, ...action.payload};
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setStageRef: (state, action) => {
      state.stageRef = action.payload
    },
    setActive: (state, action) => {
      state.active = action.payload
    },
    setUUID: (state, action) => {
      state.uuid = action.payload
    },
    setName: (state, action) => {
      state.name = action.payload
    },
    setSaved: (state, action) => {
      state.saved = action.payload
    },
    resetCanvas: (state, action) => {
      let newState = {...initialState(), stageRef: state.stageRef}
      return newState;
    },
  },
});

export const selectCanvasData = state => state.CanvasSlice.present

export const {
  setCanvasStyle,
  setImages,
  setDevices,
  setTexts,
  setFrames,
  setBackground,
  setNumberOfScreenshots,
  setMeta,
  setDimensions,
  setSelectedId,
  setStageRef,
  resetCanvas,
  setActive,
  setUUID,
  setName,
  setSaved,
  addClass,
  removeClass,
  setClassParam
} = canvasSlice.actions;

export default canvasSlice.reducer;
