import React from 'react';
import ReactModal from 'react-modal';
import CloseIcon from '../../../assets/svg/3d-maker/close.svg';
import {modal, inner, closeIcon, overlay} from './modal.module.scss';

export default ({open, canClose=true, iconStyle, className, handleClose, children}) => {
  return (
        <ReactModal 
           isOpen={open}
           contentLabel="Modal #1 Global Style Override Example"
           onRequestClose={handleClose}
           className={modal}
           overlayClassName={overlay}
           ariaHideApp={false}
        >
          <div className={`${inner} ${className}`} onMouseDown={e => e.stopPropagation()}>
          {canClose ?
            <div className={closeIcon} onClick={handleClose} style={iconStyle}>
                <CloseIcon />
            </div> : null}
            {children}
          </div>
        </ReactModal>
  );
}