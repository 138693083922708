import { createSlice } from "@reduxjs/toolkit";

const defaultTransitionStyle = (dimensions) => {
  return {
    images: [],
    devices: [],
    texts: [],
    frames: [],
    background: {
      priority: "solid",
      solid: "#4A90E2",
      linear: {
        deg: 180,
        color1: "#4e54c8",
        color2: "#8f94fb",
        percent1: 0,
        percent2: 100,
      },
      image: "https://firebasestorage.googleapis.com/v0/b/promopreview/o/collections%2Fgradients%2Fabstract_gradient_9.jpg?alt=media&token=ad57d48a-c783-4e04-9cd6-fde81b3a9d3f",
    },
    meta: {
      numOfScreenshots: 1,
      gapWidth: 56,
      dimensions: dimensions
    },
    selectedId: "background",
    stageRef: null,
  }
}

export const videoSlice = createSlice({
  name: "videoSlice",
  initialState: {
    audio: '',
    selectedVideo: 0,
    videos: []
  },
  reducers: {
    setAudio: (state, action) => {
      state.audio = action.payload;
    },
    setSelectedVideo: (state, action) => {
      state.selectedVideo = action.payload;
    },
    setVideos: (state, action) => {
        state.videos = action.payload;
    },
    deleteVideo: (state, action) => {
      state.videos[action.payload] = {}
    },
    setVideoUrl: (state, action) => {
        if(state.videos[action.payload.id] === undefined){
            state.videos[action.payload.id] = {}
        }
        if(!state.videos[action.payload.id].transition_style) {
            state.videos[action.payload.id].transition_style = defaultTransitionStyle(action.payload.dimensions)
        }
        state.videos[action.payload.id].video_url = action.payload.url;
    },
    setVideoParams: (state, action) => {
      state.videos[state.selectedVideo][action.payload.param] = action.payload.value;
    },
    setDevices: (state, action) => {
      state.videos[state.selectedVideo].transition_style.devices = action.payload
    },
    setImages: (state, action) => {
      state.videos[state.selectedVideo].transition_style.images = action.payload
    },
    setTexts: (state, action) => {
      state.videos[state.selectedVideo].transition_style.texts = action.payload
    },
    setSelectedId: (state, action) => {
      state.videos[state.selectedVideo].transition_style.selectedId = action.payload
    },
    setBackground: (state, action) => {
      state.videos[state.selectedVideo].transition_style.background = action.payload
    },
    setTransitionStyle: (state, action) => {
      state.videos[state.selectedVideo].transition_style = action.payload
    },
    setTransitionUrl: (state, action) => {
      state.videos[state.selectedVideo].transition_url = action.payload
    },
    deleteTransition: (state, action) => {
      state.videos[action.payload.id].transition_url = ""
      state.videos[action.payload.id].transition_style = defaultTransitionStyle(action.payload.dimensions)
    },
    setStageRef: (state, action) => {
      state.videos[state.selectedVideo].transition_style.stageRef = action.payload
    },
    resetSlice: (state, action) => {
      state.audio = ''
      state.selectedVideo = 0
      state.videos = []
    },
  },
});

export const selectCanvasData = state => state.VideoSlice.videos[state.VideoSlice.selectedVideo].transition_style
export const selectBackground = state => state.VideoSlice.videos[state.VideoSlice.selectedVideo].transition_style.background

export const {
    setAudio,
    setSelectedVideo,
    setVideos,
    setVideoUrl,
    setVideoParams,
    deleteVideo,
    setDevices,
    setImages,
    setTexts,
    setSelectedId,
    setBackground,
    setTransitionStyle,
    setTransitionUrl,
    deleteTransition,
    setStageRef,
    resetSlice
} = videoSlice.actions;

export default videoSlice.reducer;
