import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoginModal } from "../../../slices/user-slice";
import LoginComponent from "../../Authentication/Login/LoginComponent/LoginComponent";
import Modal from "../Modal/Modal";

export default () => {
  let dispatch = useDispatch();
  let show = useSelector((state) => state.UserSlice.loginModal);
  let user = useSelector((state) => state.UserSlice.user);

  React.useEffect(() => {
    if (user) {
      dispatch(setLoginModal(false));
    }
  }, [user]);

  return (
    <Modal
      open={show && !user}
      handleClose={() => dispatch(setLoginModal(false))}
    >
      <div style={{padding: 40}}>
        <LoginComponent style={{ maxWidth: 350 }} />
      </div>
    </Modal>
  );
};
