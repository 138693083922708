import { store } from "../../configureStore";
import { hasPaid } from "../components/Common/Functions";
import { setAttributionModal } from "../slices/user-slice";
import Firebase from "./index";
const axios = require("axios");

function addParams(data){
  let params = ''
  let keys = Object.keys(data)
  for(let i=0; i < keys.length; i++){
    let key = keys[i]
    if(i === 0){
      params = `${key}=${data[key]}`
    }
    else{
      params = `${params}&${key}=${data[key]}`
    }
  }
  return params
}

class API {
  get = async (endpoint, auth = true) => {
    if (auth) {
      var token = await Firebase.getCurrectUserToken();
      if (!token){
        return null
      }
    }
    let response = await axios
      .get(
        process.env.GATSBY_API_URL + endpoint,
        auth
          ? {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          : null
      )
      .catch((error) => {
        return error;
      });
    return response.data;
  };

  post = async (endpoint, value, auth = true) => {
    if (auth) {
      var token = await Firebase.getCurrectUserToken();
      if (!token){
        return null
      }
    }
    let response = await axios
      .post(
        process.env.GATSBY_API_URL + endpoint,
        value,
        auth
          ? {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          : null
      )
      .catch((error) => {
        return error;
      });
    return response.data;
  };

  put = async (endpoint, value) => {
    let token = await Firebase.getCurrectUserToken();
    if (!token){
      return null
    }
    let response = await axios
      .put(process.env.GATSBY_API_URL + endpoint, value, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((error) => {
        return error;
      });
    return response.data;
  };

  createNewUser = async (email) => {
    let via = document.cookie
    .split('; ')
    .find(row => row.startsWith('via='))
    ?.split('=')[1];

    return await this.post("users", { email , via });
  };

  deleteUser = async () => {
    return await this.post("users/delete");
  };

  getCurrentUser = async () => {
    return await this.get("users");
  };

  updateCurrentUser = async (data) => {
    return await this.put("users", data);
  };

  getCurrentUserTemplates = async () => {
    return await this.get("templates");
  };

  saveNewTemplateUser = async (id, data) => {
    return await this.post(`templates/${id}`, data);
  };

  saveNewTemplateGroup = async (id, group, data) => {
    return await this.post(`templates/${id}/${group}`, data);
  };

  copyTemplate = async (id) => {
    return await this.post(`templates/${id}/copy`);
  };

  moveTemplate = async (id, owner_id=null) => {
    return await this.put(`templates/${id}/move`, {owner_id});
  };

  editTemplate = async (id, values) => {
    return await this.put(`templates/${id}`, values ? values : { a: 1 });
  };

  deleteTemplate = async (id) => {
    return await this.put(`templates/${id}`, { status: "deleted" });
  };

  canUserDownload = async () => {
    return await this.get("downloads");
  };

  onUserDownload = async (type, watermark) => {
    // User downloaded something
    // Check if paid or not
    // If not paid --> show attribution modal

    if(!await hasPaid()){
      store.dispatch(setAttributionModal(true))
    }
    return this.post("downloads", { type, watermark });
  };

  getUserGroups = async () => {
    return await this.get("groups");
  };

  createNewGroup = async (name) => {
    return await this.post(`groups`, { name });
  };

  getGroupInfo = async (id) => {
    return await this.get(`groups/${id}`);
  };

  getGroupTemplates = async (id) => {
    return await this.get(`templates/${id}`);
  };

  getUserSubscription = async () => {
    return await this.get("subscription");
  };

  getUserPayments = async () => {
    return await this.get("subscription/payments");
  };

  cancelSubscription = async (id) => {
    return await this.post(`subscription/cancel/${id}`);
  };

  startStripeCheckout = async (price_id, mode) => {
    return await this.post(`subscription/create`, { price_id, mode });
  };

  inviteUsersToGroup = async (invitees, group_id) => {
    return await this.post(`group-invites`, { invitees, group_id });
  };

  getPendingUserInvites = async () => {
    return await this.get("group-invites");
  };

  answerInvite = async (inviteId, status) => {
    return await this.put(`group-invites/${inviteId}`, { status });
  };

  contactForm = async (form) => {
    return await this.post(`forms/contact-form-message`, form, false);
  };

  leaveGroup = async (id) => {
    return await this.post(`groups/${id}/leave`);
  };

  editGroup = async (id, values) => {
    return await this.put(`groups/${id}`, values);
  };

  getSearch = async () => {
    return await this.get("search", false);
  };

  getCategoryTemplates = async (data) => {
    let params = addParams(data)
    return await this.get(`templates/get-templates-matching-category?${params}`, false);
  };

  getSearchedTemplates = async (data) => {
    let params = addParams(data)
    return await this.get(`templates/get-templates-from-search-query?${params}`, false);
  }

  logSearch = async (query) => {
    return await this.post(`search`, {query}, false);
  };

  logTemplateActivity = async (data) => {
    return await this.post(`templates/template-activity`, data, false);
  };

  getDeviceFrame = async (id) => {
    return await this.get(`devices/${id}`, false);
  };

  getAllFrames = async () => {
    return await this.get(`devices`, false);
  }

  getAllCollections = async () => {
    return await this.get(`collections`, false);
  }
  getCollectionImages = async (id) => {
    return await this.get(`collections/images/${id}`, false);
  } 
  findCollectionImage = async (string) => {
    return await this.get(`collections/path-lookup/${string}`, false);
  } 
  doesTemplateExist = async (id) => {
    return await this.get(`templates/check-if-exists/${id}`)
  }
  getUserFonts = async () => {
    return await this.get(`brand_kit/fonts/my-fonts`)
  }
  setUserFont = async (id, data) => {
    return await this.post(`brand_kit/fonts/${id}`, data)
  }
  getCustomFont = async (id) => {
    return await this.get(`brand_kit/fonts/${id}`)
  }
  getReferrals = async () => {
    return await this.get(`referrals`)
  }
  getLastWeeksReferrals = async () => {
    return await this.get(`referrals/last-4-weeks`)
  }
  getReferralsPayouts = async () => {
    return await this.get(`referrals/payouts`)
  }
  setReferralAccount = async (data) => {
    return await this.post(`referrals`, data)
  }
}

export default new API();
