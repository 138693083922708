import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "userSlice",
  initialState: {
    user: null,
    userInfo: null,
    loginModal: false,
    loader: false,
    creditsModal: false,
    attributionModal: false,
    shareModal: false,
    showShareModal: false,
    activeCanvas: '2d',
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
    setShareModal: (state, action) => {
      state.shareModal = action.payload
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload
    },
    setLoginModal: (state, action) => {
      state.loginModal = action.payload
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setCreditsModal: (state, action) => {
      state.creditsModal = action.payload
    },
    setAttributionModal: (state, action) => {
      state.attributionModal = action.payload
    },
    setShowShareModal: (state, action) => {
      state.showShareModal = action.payload
    },
    setActiveCanvas: (state, action) => {
      state.activeCanvas = action.payload
    },
  },
});


export const {
  setUser,
  setShareModal,
  setUserInfo,
  setLoginModal,
  setLoader,
  setCreditsModal,
  setAttributionModal,
  setShowShareModal,
  setActiveCanvas,
} = userSlice.actions;

export default userSlice.reducer;
