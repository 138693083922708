import { Link, navigate } from "gatsby"
import React from 'react'
import RightArrowSvg from "../../../../assets/svg/chevron-right.svg"
import GoogleSvg from "../../../../assets/svg/google.svg"
import Firebase from '../../../../firebase/index'
import Form from "../Form"
import * as styles from './logincomponent.module.scss'
import SocialButton from '../SocialButton/SocialButton'
import { useDispatch } from "react-redux"
import { setLoginModal } from "../../../../slices/user-slice"

export default ({style}) => {
  let dispatch = useDispatch()
  function onRegister(){
    dispatch(setLoginModal(false))
    navigate('/register')
  }
    return(
        <div className={styles.inner} style={style}>
        <h2>Log in</h2>
        <div className={styles.altLogin}>
        <SocialButton icon={<GoogleSvg />} text={'Log in with Google'} onClick={Firebase.loginWithGoogle} />
        <span>
            - OR -
        </span>
        </div>
        <div className={styles.form}>
          <Form />
        </div>
        <div className={styles.register}>
          Don't have an account?
          <div onClick={onRegister}><span>Register here</span><RightArrowSvg /></div>
        </div>
      </div>
    )
}