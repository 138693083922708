export function undoable(reducer) {
  // Call the reducer with empty action to populate the initial state
  const initialState = {
    past: [],
    present: reducer(undefined, {}),
    future: [],
  };

  // Return a reducer that handles undo and redo
  return function (state = initialState, action) {
    const { past, present, future } = state;

    switch (action.type) {
      case "UNDO":
        // If no past, do nothing
        if (!past.length) return {...state}
        // If last item
        if (past.length === 1) {
          return {
            ...state,
            present: past[0],
            future: present === past[0] ? [...future] : [present, ...future],
          };
        }
        let back = present === past[past.length - 1] ? 2 : 1;
        const previous = past[past.length - back];
        const newPast = past.slice(0, past.length - back);

        return {
          past: newPast,
          present: previous,
          future: [present, ...future],
        };
      case "REDO":
        if (!future.length) {
          return state;
        }
        const next = future[0];
        const newFuture = future.slice(1);
        return {
          past: [...past, present],
          present: next,
          future: newFuture,
        };
      case "ADD_TO_HISTORY":
        return {
          ...state,
          past: [...past, present],
          save: false,
        };
      default:
        // Delegate handling the action to the passed reducer
        const newPresent = reducer(present, action);
        if (present === newPresent) {
          return state;
        }

        let save = false;
        if (actions.includes(action.type)) {
          save = true;
        }

        if (loadingActions.includes(action.type)) {
          // Style is loaded --> Set history, remove future
          return {
            past: [{ ...newPresent }],
            present: newPresent,
            future: [],
          };
        }

        // let initPast = [];
        // if (action.type === "persist/REHYDRATE") {
        //   initPast = [{ ...newPresent }];
        // }

        return {
          past: future.length ? [...past, present] : [...past],
          present: newPresent,
          future: [],
          save,
        };
    }
  };
}

export function listener(store) {
  var timer = null;
  store.subscribe(() => {
    let data = store.getState();
    slices.forEach((name) => {
      if (data[name].past.length) {
        let lastHistoryItem = data[name].past[data[name].past.length - 1];
        if (data[name].present !== lastHistoryItem && data[name].save) {
          if (timer !== null) {
            clearTimeout(timer);
          }
          timer = setTimeout(function () {
            store.dispatch({ type: "ADD_TO_HISTORY" });
          }, 500);
        }
      }
    });
  });
}

const actions = [
  "ScreenshotsCanvasThreeD/setBackground",
  "ScreenshotsCanvasThreeD/setCameraParams",
  "ScreenshotsCanvasThreeD/addClass",
  "ScreenshotsCanvasThreeD/setClassParam",
  "ScreenshotsCanvasThreeD/setFloorParam",
  "ScreenshotsCanvasThreeD/setDimensions",

  "canvasSlice/setBackground",
  "canvasSlice/setDevices",
  "canvasSlice/setImages",
  "canvasSlice/setTexts",
  "canvasSlice/setDimensions",
  "canvasSlice/setNumberOfScreenshots",

  "threeSlice/setClassParam",
  "threeSlice/addDevice",
  "threeSlice/addText",
  "threeSlice/addImage",
  "threeSlice/setDimensions",
  "threeSlice/cloneItem",
  "threeSlice/updateTime",
  "threeSlice/setObjectAnimationParam",
];

const slices = ["ScreenshotsCanvasThreeD", "CanvasSlice", "ThreeSlice"];

const loadingActions = ["ScreenshotsCanvasThreeD/setRenderer", "canvasSlice/setStageRef", "threeSlice/setScene"];
