import firebase from "gatsby-plugin-firebase";
import { toast } from 'react-toastify';
import { store } from '../../configureStore';
import { setUser, setUserInfo } from '../slices/user-slice';
import { push_event_to_datalayer } from "../tools/analytics";
import LogoImage from '../assets/images/square-logo.png'
import API from './api';
import { referralUser } from "../components/Common/Functions";

require("firebase/firestore");

var db, storage
if(typeof window !== 'undefined'){
  db = firebase.firestore();
  storage = firebase.storage()
}





class Firebase { 
  getFileFromStorage = async (folder, name) => {
    try {
      let url = await storage.ref().child(`${folder}/${name}`).getDownloadURL()
      return url
    }
    catch {
      console.error('❌ Something went wrong...')
    }
  }
  saveFileToStorage = async (name, folder, file) => {
      try {
        let response = await storage.ref().child(folder).child(name).put(file)
        if(response && response.state === "success"){
          // File uploaded
          return true
        }
      }
      catch {
        toast.error('❌ Something went wrong...')
      }
  }

  saveTemplateToStorage = async (data, thumbnail) => {
    let jsonToUpload = JSON.stringify(data);
    let jsonBlob = new Blob([jsonToUpload], { type: "application/json" });
    let tempalteResponse = await storage.ref().child('templates').child(data.uuid).put(jsonBlob).catch(() => {
      toast.error('❌ Something went wrong...')
      return false
    })
    if(tempalteResponse && tempalteResponse.state === "success"){
      let blob = await (await fetch(thumbnail)).blob();
      let extension = blob.type.includes('video') ? 'mp4' : 'png'
      let thumbnailResponse = await storage.ref().child('thumbnails').child(`${data.uuid}.${extension}`).put(blob).catch(() => {
        toast.error('❌ Something went wrong...')
        return false
      })
      if(thumbnailResponse && thumbnailResponse.state === "success"){
        return extension
      }
    }
    return
  }
  deleteSavedTemplate = async (id) => {
    let user_id = this.getCurrentUserId()
    let dataRef = db.collection("previewed").doc(user_id);
    dataRef.update({templates: firebase.firestore.FieldValue.arrayRemove(id)})
    return
}
  setSavedTemplates = (templates) => {
      let user_id = this.getCurrentUserId()
      let dataRef = db.collection("previewed").doc(user_id);
      dataRef.set({templates}, {merge: true})
  }
  getSavedTemplates = async () => {
    let user_id = this.getCurrentUserId()
    let dataRef = db.collection("previewed").doc(user_id);
    let doc = await dataRef.get()
    if (doc.exists) {
        let data = doc.data()
        return data.templates
    } else {
        // doc.data() will be undefined in this case

    }
  }

      reduceCredits = async () => {
        let currentCredits = await this.getCredits()
        if(currentCredits === 'unlimited'){
          return
        }
        else{
          this.setCredits(currentCredits - 1)
        }
      }

      setCredits = (credits) => {
        let user_id = this.getCurrentUserId()
        let dataRef = db.collection("previewed").doc(user_id);
        dataRef.update({credits})
      }

      userPaid = async () => {
        let user_id = this.getCurrentUserId()
        let dataRef = db.collection("previewed").doc(user_id);
        let doc = await dataRef.get()
        if (doc.exists) {
            let data = doc.data()
            if(data.paid){
              return true
            }
            else{
              return false
            }
        } else {
            // doc.data() will be undefined in this case
        }
      }

      getCredits = async () => {
        let user_id = this.getCurrentUserId()
        let dataRef = db.collection("previewed").doc(user_id);
        let doc = await dataRef.get()

        if (doc.exists) {
            let data = doc.data()
            if(data.unlimited || data.subscription && (data.subscription.status === "active" ||  data.subscription.status === "trialing")){
              return 'unlimited'
            }
            else{
              return data.credits
            }
        } else {
            // doc.data() will be undefined in this case
        }
      }

      getSubscription = async () => {
        let loggedIn = store.getState().UserSlice.user
        if(!loggedIn) return false
        let user_id = this.getCurrentUserId()
        let dataRef = db.collection("previewed").doc(user_id);
        let doc = await dataRef.get()

        if (doc.exists) {
            let data = doc.data()
            return data.subscription
        } else {
            // doc.data() will be undefined in this case
            return false
        }
      }

      setNewUserData = (user) => {
        let dataRef = db.collection("previewed").doc(user.uid);

        dataRef.set({
            credits: 0
        })
        .then(() => {
            // console.log("Credits added");
        })
        .catch(function(error) {
            console.error("Error adding credits: ", error);
        });
      }

      signIn(){
        store.dispatch(setUser(true))
      }

    signOut(){
        firebase.auth().signOut().catch(function(error) {
            // An error happened.
            });
    }

    initialiseUser() {
      if(typeof window !== 'undefined'){
        firebase.auth().onAuthStateChanged(async (user) => {
          if (user) {
            // User is signed in.
            let userInfo = await API.getCurrentUser()
            if(!userInfo){
              userInfo = await API.createNewUser(user.email)
            }
            store.dispatch(setUserInfo({...userInfo, emailVerified: user.emailVerified}))
            store.dispatch(setUser(true))
          } else {
            // No user is signed in.
            store.dispatch(setUser(false))
            store.dispatch(setUserInfo(null))
          }
        });
      }
      }

    async getCurrectUserToken(){
        let currentUser = firebase.auth().currentUser
        if (currentUser) {
          let token = await currentUser.getIdToken()
          return token
        }
        return null
      }

    sendEmailVerification(){
        let currentUser = firebase.auth().currentUser
        currentUser.sendEmailVerification().then(() => {
          toast.success('Check your e-mail for the verification link.')
        })
      }

     getCurrentUserInfo(){
        let currentUser = firebase.auth().currentUser
        let providerIsEmail = currentUser.providerData[0].providerId === 'password'
        let info =  {photo: currentUser.photoURL ? currentUser.photoURL : LogoImage, displayName: currentUser.displayName, email: currentUser.email, emailVerified: providerIsEmail ? currentUser.emailVerified : true}
        return info
      }

    getCurrentUserId(){
        return firebase.auth().currentUser.uid
    }

    login = async (email, password) => {
      let response = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .catch(function(error) {
          // Handle Errors here.
          if (error.code === "auth/user-not-found") {
            toast.error(`🕵️‍♂️ The user doesn't exist.`)
          }
          if (error.code === "auth/wrong-password") {
            toast.error(`🔑 The password is incorrect.`)
          }
  
          return
          // ...
        })
      return response
    }

    async register(email, password) {
      let response = await firebase.auth().createUserWithEmailAndPassword(email, password).catch((error) => {
        if(error.code === "auth/email-already-in-use"){
          toast.error('This e-mail is already associated with an account.')
        }
        else if (error.code === "auth/invalid-email"){
          toast.error('The e-mail address is invalid.')
        }
        return null
      })
      try {
        await API.createNewUser(email)
        await response.user.sendEmailVerification()
        push_event_to_datalayer('user-created');
      }
      catch {
        // Something went wrong
      }
      return response.user
    }

    async resetPassword(email) {
      return new Promise((resolve) => {
        firebase.auth().sendPasswordResetEmail(email).then(() => {
         resolve(true) 
       }).
       catch(error => {
         if(error.code === "auth/user-not-found"){
           toast.error(`🕵️‍♂️ The user doesn't exist.`)
         }
         resolve(false) 
       })
     })
    }

    loginWithGoogle = async () => {
      let provider = new firebase.auth.GoogleAuthProvider()
      let response = await firebase
        .auth()
        .signInWithPopup(provider)
        .catch(function(error) {
          // Handle Errors here.
          var errorCode = error.code
        })
      if(response && response.additionalUserInfo.isNewUser){
        push_event_to_datalayer('user-created');
        await API.createNewUser(response.user.email)
      }
  
      return
    }

    getTemplateThumbnail = async (thumbnail) => {
            let url = await storage
            .ref()
            .child("thumbnails")
            .child(thumbnail)
            .getDownloadURL()

            return url
     }

     getTemplateStyle = async (id) => {
      let url = await storage
      .ref()
      .child("templates")
      .child(id)
      .getDownloadURL()
      if(url){
          let res = await fetch(url);
          let blob = await res.blob();
          let data = await blob.text();
          let style = JSON.parse(data);
          return style
        }
        else {
          toast.error('Unable to load template.')
        }
      }

      getImageFromStorage = async (path) => {
        let url = await storage
        .ref(path)
        .getDownloadURL()

        return url
      }
  }
  
  export default new Firebase()
  export var storage