import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const initialState = () => {
  return {
    background: {
      priority: "solid",
      solid: "rbga(255,255,255,1)",
      linear: {
        deg: 180,
        color1: "#4e54c8",
        color2: "#8f94fb",
        percent1: 0,
        percent2: 100,
      },
      image: "https://firebasestorage.googleapis.com/v0/b/promopreview/o/collections%2Fgradients%2Fabstract_gradient_9.jpg?alt=media&token=ad57d48a-c783-4e04-9cd6-fde81b3a9d3f",
    },
    texts: [],
    devices: [],
    images: [],
    hovered: {
      object: null,
      group: false,
      resize: false,
    },
    clicked: {
      object: null,
      group: false
    },
    camera: {
      zooms: [1],
      positions: [{x:0, y:0, z: 100}]
    },
    control: 'objects',
    dragging: false,
    selectedId: 'background',
    dimensions: {
      width: 500,
      height: 500,
    },
    textEditor: false,
    checkpoints: {
      durations: [0],
      transitions: [],
      current: 0,
    },
    playback: false,
    recording: {
      text: '',
      scale: null,
      progress: null,
      ready: false
    },
    renderer: null,
    active: false,
    name: '',
    uuid: uuidv4(),
    floor: {
      active: false,
      y: -10,
      opacity: 0.25,
      blur: 1,
    },
  }
} 
export const ScreenshotsCanvasThreeD = createSlice({
  name: "ScreenshotsCanvasThreeD",
  initialState: initialState(),
  reducers: {
    setFloorParam: (state, action) => {
      state.floor[action.payload.param] = action.payload.value
    },
    setActive: (state, action) => {
      state.active = action.payload
    },
    setResize: (state, action) => {
      state.hovered.resize = action.payload
    },
    setBackground: (state, action) => {
      state.background = action.payload
    },
    setBackgroundImage: (state, action) => {
      state.background.image = action.payload
    },
    setScreenshot: (state, action) => {
      state.device.screenshot = action.payload
    },
    setHovered: (state, action) => {
      state.hovered = {...state.hovered, ...action.payload}
    },
    setClicked: (state, action) => {
      state.clicked = action.payload
    },
    setRotation: (state, action) => {
      state.device.rotation[action.payload.axis] = action.payload.value
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload
    },
    setText: (state, action) => {
      state.texts[action.payload.id] = action.payload.text
    },
    deleteText: (state, action) => {
      state.selectedId = 'background'
      state.texts[action.payload] = {}
    },
    addText: (state, action) => {
      let positions = state.checkpoints.durations.reduce((list, index) => {
        list.push({x:0, y:0, z:0})
        return list
    }, [])
    let visible = state.checkpoints.durations.reduce((list, value, index) => {
      if(index < state.checkpoints.current){
        list.push(false)
      }
      else{
        list.push(true)
      }
      return list
  }, [])
      let newText = {
      text: 'New Text',
      fontFamily: 'Lobster',
      fill: 'rgba(0,0,0,1)',
      fontWeight: '400',
      textAlign: 'left',
      italic: false,
      lineHeight: 1,
      fontSize: 20,
      scale: 10,
      positions: positions,
      visible: visible,
      animation: 'fade-in',
      id: `text${state.texts.length}`
    }
      state.texts = [...state.texts, {...newText}]
    },
    setDimensions: (state, action) => {
      state.dimensions = action.payload
    },
    setDevice: (state, action) => {
      state.devices[action.payload.id] = action.payload.device
    },
    setClassParam: (state, action) => {
      if(action.payload.axis){ // If axis then position/rotation
        state[action.payload.class][action.payload.id][action.payload.param][state.checkpoints.current][action.payload.axis] = action.payload.value
      }
      else if(action.payload.position){ // If drag/drop position change
        state[action.payload.class][action.payload.id].positions[state.checkpoints.current] = action.payload.position
      }
      else {
        state[action.payload.class][action.payload.id][action.payload.param] = action.payload.value
      }
    },
    addClass: (state, action) => {
      let newItem
      let positions = state.checkpoints.durations.reduce((list, index) => {
        list.push({x:0, y:0, z:0})
        return list
    }, [])
    let visible = state.checkpoints.durations.reduce((list, value, index) => {
      if(index < state.checkpoints.current){
        list.push(false)
      }
      else{
        list.push(true)
      }
      return list
  }, [])
  let rotations = state.checkpoints.durations.reduce((list, index) => {
    list.push({x:0, y:0, z:0})
    return list
}, [])
      if(action.payload === 'texts'){
        newItem = {
          text: 'New Text',
          fontFamily: 'Lobster',
          fill: 'rgba(0,0,0,1)',
          fontWeight: '400',
          textAlign: 'left',
          italic: false,
          lineHeight: 1,
          fontSize: 20,
          scale: 10,
          positions: positions,
          visible: visible,
          animation: 'fade-in',
          id: `text${state.texts.length}`
        }
      }
      else if(action.payload === 'images'){
        newItem = {
          img: "https://firebasestorage.googleapis.com/v0/b/promopreview/o/collections%2Fbadges%2Fdownload_on_the_app_store_badge_us_uk_rgb_blk_092917.png?alt=media&token=7f6f0b82-f9f2-4604-8ab4-7848acb2f80a",
          scale: 1, 
          positions,
          visible,
          animation: 'fade-in',
          id: `image${state.images.length}`
        }
      }
      else {
        newItem = {
          rotations,
          positions,
          screenshot: null,
          color: 'rgb(0, 0, 0)',
          scale: 0.05,
          frame: 'iPad',
          id: `device${state.devices.length}`
        }
      }
      state[action.payload] = [...state[action.payload], newItem]
    },
    removeClass: (state, action) => {
      state.selectedId = 'background'
      state[action.payload.class][action.payload.id] = {}
    },
    setDevicePositions: (state, action) => {
      state.devices[action.payload.id].positions = action.payload.positions
    },
    addDevice: (state, action) => {
      let positions = state.checkpoints.durations.reduce((list, index) => {
        list.push({x:0, y:0, z:10})
        return list
    }, [])
    let rotations = state.checkpoints.durations.reduce((list, index) => {
      list.push({x:0, y:0, z:0})
      return list
  }, [])
      let newDevice = {
      rotations,
      positions,
      screenshot: null,
      color: 'rgb(0, 0, 0)',
      scale: 0.05,
      frame: 'iPad',
      id: `device${state.devices.length}`
    }
      state.devices = [...state.devices, {...newDevice}]
    },
    setImage: (state, action) => {
      state.images[action.payload.id] = action.payload.image
    },
    addImage: (state, action) => {
      let positions = state.checkpoints.durations.reduce((list, index) => {
        list.push({x:0, y:0, z:5})
        return list
    }, [])
    let visible = state.checkpoints.durations.reduce((list, value, index) => {
      if(index < state.checkpoints.current){
        list.push(false)
      }
      else{
        list.push(true)
      }
      return list
  }, [])
      let newImage = {
        img: "https://firebasestorage.googleapis.com/v0/b/promopreview/o/collections%2Fbadges%2Fdownload_on_the_app_store_badge_us_uk_rgb_blk_092917.png?alt=media&token=7f6f0b82-f9f2-4604-8ab4-7848acb2f80a",
        scale: 1, 
        positions,
        visible,
        animation: 'fade-in',
        id: `image${state.images.length}`
      }
      state.images = [...state.images, {...newImage }]
    },
    setScale: (state, action) => {
      state[action.payload.type][action.payload.id].scale = state[action.payload.type][action.payload.id].scale * action.payload.scale
    },
    setTextEditor: (state, action) => {
      state.textEditor = action.payload
    },
    setCheckpointDuration: (state, action) => {
      state.checkpoints.durations[action.payload.id] = action.payload.duration
    },
    setCheckpointCurrent: (state, action) => {
      state.checkpoints.current = action.payload
    },
    setCheckpointTransition: (state, action) => {
      state.checkpoints.transitions[action.payload.index] = action.payload.transition
    },
    setCheckpoints: (state, action) => {
      state.checkpoints = action.payload
    },
    addCheckpoint: (state, action) => {
      state.checkpoints.durations = [...state.checkpoints.durations, action.payload.duration]
      state.checkpoints.transitions = [...state.checkpoints.transitions, 'Quadratic.InOut']
      state.images = action.payload.images
      state.texts = action.payload.texts
      state.devices = action.payload.devices
      state.camera.positions = [...state.camera.positions, state.camera.positions[state.camera.positions.length - 1]]
      state.camera.zooms = [...state.camera.zooms, state.camera.zooms[state.camera.zooms.length - 1]]
    },
    deleteCheckpoint: (state, action) => {
      state.checkpoints.durations.splice(action.payload, 1)
      state.checkpoints.transitions.splice(action.payload - 1, 1)
      state.images.forEach(image => {
        if(Object.keys(image).length !== 0){
          image.positions.splice(action.payload, 1)
          image.visible.splice(action.payload, 1)
        }
      })
      state.texts.forEach(text => {
        if(Object.keys(text).length !== 0){
          text.positions.splice(action.payload, 1)
          text.visible.splice(action.payload, 1)
        }
      })
      state.devices.forEach(device => {
        if(Object.keys(device).length !== 0){
          device.positions.splice(action.payload, 1)
          device.rotations.splice(action.payload, 1)
        }
      })
      state.camera.positions.splice(action.payload, 1)
      state.camera.zooms.splice(action.payload, 1)
      state.checkpoints.current = action.payload - 1
    },
    setPlayback: (state, action) => {
      state.playback = action.payload
    },
    setRecordingScale: (state, action) => {
      state.recording.scale = action.payload
    },
    setRecordingText: (state, action) => {
      state.recording.text = action.payload
    },
    setRecordingProgress: (state, action) => {
      state.recording.progress = action.payload > 100 ? 100 : action.payload < 0 ? 0 : action.payload
    },
    startRecording: (state, action) => {
      state.recording.ready = true
    },
    cancelRecording: (state, action) => {
      state.recording.progress = null
      state.recording.scale = null
      state.recording.ready = false
    },
    setRenderer: (state, action) => {
      state.renderer = action.payload
    },
    setThreeDCanvasStyle: (state, action) => {
      state.images = action.payload.images;
      state.devices = action.payload.devices;
      state.texts = action.payload.texts;
      state.background = action.payload.background;
      state.dimensions = action.payload.dimensions;
      state.selectedId = action.payload.selectedId || 'background';
      state.camera = action.payload.camera;
      state.checkpoints = {
        current: 0,
        durations: action.payload.checkpoints.durations,
        transitions: action.payload.checkpoints.transitions || []
      }
      state.originalTemplateId = action.payload.originalTemplateId
      state.name = action.payload.name;
      state.uuid = action.payload.uuid;
      state.saved = action.payload.saved;
      state.floor = action.payload.floor || {...initialState().floor}
    },

    setDragging: (state, action) => {
      state.dragging = action.payload
    },
    setControl: (state, action) => {
      state.control = action.payload
    },
    setName: (state, action) => {
      state.name = action.payload
    },
    setCameraParams: (state, action) => {
      state.camera.positions[action.payload.id] = action.payload.position
      state.camera.zooms[action.payload.id] = action.payload.zoom
    },
    setCameraZooms: (state, action) => {
      state.camera.zooms.forEach((item) => {
        item * action.payload.newZoom
      })
    },
    resetCanvas: (state, action) => {
      let newState = {...initialState(), renderer: state.renderer}
      return newState;
    },

  },
});


export const {
  setResize,
  setBackground,
  setBackgroundImage,
  setHovered,
  setRotation,
  setSelectedId,
  setScreenshot,
  setText,
  setDimensions,
  setDevice,
  setDevicePositions,
  addDevice,
  addText,
  setImage,
  addImage,
  setClicked,
  setTextEditor,
  setCheckpoints,
  addCheckpoint,
  setPlayback,
  setCheckpointDuration,
  setCheckpointCurrent,
  deleteCheckpoint,
  setScale,
  setRecordingScale,
  setRecordingProgress,
  setRecordingText,
  setRenderer,
  startRecording,
  cancelRecording,
  setThreeDCanvasStyle,
  setActive,
  setDragging,
  setControl,
  setCameraParams,
  setCameraZooms,
  setName,
  resetCanvas,
  setCheckpointTransition,
  deleteText,
  setFloorParam,
  setRef,
  setClassParam,
  addClass,
  removeClass,
  setScene
} = ScreenshotsCanvasThreeD.actions;

export default ScreenshotsCanvasThreeD.reducer;
