// configureStore.js
import { combineReducers } from "@reduxjs/toolkit";
import localForage from "localforage";
import { createStore } from 'redux';
import { persistReducer, persistStore } from "redux-persist";
import CanvasSlice from './src/slices/canvas-slice';
import ScreenshotsCanvasThreeD from './src/slices/threed-canvas-screenshot';
import UserSlice from './src/slices/user-slice';
import VideoSlice from './src/slices/video-slice';
import ThreeSlice from './src/slices/three-slice';
import {undoable, listener} from './src/tools/undoable';


const rootPersistConfig = {
  key: 'root',
  storage: localForage,
  blacklist: ['CanvasSlice', 'VideoSlice', 'UserSlice', 'ScreenshotsCanvasThreeD', 'ThreeSlice']
}

const canvasPersistConfig = {
  key: 'CanvasSlice',
  storage: localForage,
  whitelist: ['background', 'images', 'devices', 'meta', 'texts', 'selectedId', 'active', 'uuid', 'name', 'saved', 'originalTemplateId']
  // blacklist: ['stageRef']
}

const threedCanvasPersistConfig = {
  key: 'ScreenshotsCanvasThreeD',
  storage: localForage,
  whitelist: ['background', 'images', 'devices', 'dimensions', 'texts', 'floor', 'selectedId', 'checkpoints', 'active', 'camera', 'name', 'uuid', 'saved', 'originalTemplateId']
  // blacklist: ['stageRef']
}

const videoPersistConfig = {
  key: 'VideoSlice',
  storage: localForage,
  blacklist: ['stageRef']
}

const threeSliceConfig = {
  key: 'ThreeSlice',
  storage: localForage,
  whitelist: ['background','animated', 'images', 'devices', 'dimensions', 'texts', 'camera', 'duration', 'plane', 'name', 'uuid', 'originalTemplateId']
}

const rootReducer = combineReducers({
  CanvasSlice: undoable(persistReducer(canvasPersistConfig, CanvasSlice)),
  VideoSlice: persistReducer(videoPersistConfig, VideoSlice),
  ScreenshotsCanvasThreeD: undoable(persistReducer(threedCanvasPersistConfig, ScreenshotsCanvasThreeD)),
  UserSlice,
  ThreeSlice: undoable(persistReducer(threeSliceConfig, ThreeSlice))
});
 
const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

export const store = createStore(persistedReducer)

listener(store)

export const persistor = persistStore(store)

export default { store, persistor }