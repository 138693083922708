import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import React from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { store } from "./configureStore";
import FullScreenLoader from "./src/components/Common/FullScreenLoader/FullScreenLoader";
import SignInModal from "./src/components/Modals/SignInModal/SignInModal";
import Firebase from "./src/firebase/index";
import "./src/styles/index.css";
import Helmet from "react-helmet";
import { withPrefix } from "gatsby";

require("typeface-poppins");

export const wrapRootElement = ({ element }) => {
  Firebase.initialiseUser();
  return (
    <Provider store={store}>
      <Helmet>
        <script src={withPrefix('scripts/referral.js')} />
      </Helmet>
      <FullScreenLoader />
      <SignInModal />
      <ToastContainer />
      {element}
    </Provider>
  );
};
