import React from 'react'
import { Form, Formik } from 'formik'
import FormInput from './FormInput/FormInput'
import MoonLoader from "react-spinners/MoonLoader"
import * as styles from './form.module.scss'

export default ({initialValues, validationSchema, onSubmit, fields, submitButtonStyle, submitButtonText }) => {
    const Fields = ({errors, touched}) => fields.map((field) => {
        let {name, type, label, info, placeholder, to} = field
        return(
            <FormInput key={name} to={to} placeholder={placeholder} type={type} name={name} error={errors[name] && touched[name]} label={label} info={info}/>
        )
    })
    return(
        <Formik
        enableReinitialize 
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
            await onSubmit(values)
            setSubmitting(false)
        }}
        >
        {({ errors, touched, isSubmitting }) => (
            <Form className={styles.form}>
                <Fields errors={errors} touched={touched} />
                <button type={'submit'} className={styles.submitButton} style={submitButtonStyle}>
                    {isSubmitting ? <MoonLoader size={19} color={"#ffffff"} loading={true} /> : submitButtonText || `Submit`}
                </button>
            </Form>
        )}
    </Formik>
    )
}