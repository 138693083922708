import { toast } from "react-toastify";
import { store } from "../../../configureStore";
import API from "../../firebase/api";
import Firebase from "../../firebase/index";
import {
  setCreditsModal,
  setLoader,
  setLoginModal,
  setShowShareModal,
} from "../../slices/user-slice";
import { v4 as uuidv4 } from "uuid";
import React from 'react'
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import * as THREE from 'three'


export async function hasPaid() {
  try {
    let response = await API.canUserDownload();
    // check if unlimited or subscribed
    if (response.subscription || response.unlimited) {
      return true
    }
    // check if has credits
    if (response.credits && response.credits > 0) {
      return true
    }
    // else has not paid
    return false
  }
  catch(err){
    console.log(err)
    return false
  }
}

async function hasFreeDownload(){
  let response = await API.canUserDownload();
  if (response["has-free-download"]) {
      return true
  }
  return false
}

export async function canUsePremiumFeature(){
  // if paid --> can use it
  if(await hasPaid()) return true
  // else show upgrade popup
  store.dispatch(setCreditsModal(true));
  return false
}


export async function canDownload(free) {
  let user = store.getState().UserSlice.user;
  if (!user) {
    store.dispatch(setLoginModal(true));
    return false;
  } else if (!Firebase.getCurrentUserInfo().emailVerified) {
    toast.error("📧 Please verify your e-mail");
    return false;
  }
  let paid = await hasPaid();

  if (paid || free) {
    return true
  }

  // Upgrade to Premium
  store.dispatch(setCreditsModal(true));
  return false
}


export function updateUUID(data) {
  let uuid = uuidv4();
  data.uuid = uuid;
  return data;
}

export function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

export function getDataAndThumbnail(type) {
  let data, thumbnail
  if (type === "2d") {
    data = {
      ...store.getState().CanvasSlice.present,
      _persist: null,
      stageRef: null,
    };
    thumbnail = store.getState().CanvasSlice.present.stageRef.toDataURL();
  } else {
    let {
      background,
      texts,
      devices,
      images,
      camera,
      dimensions,
      checkpoints,
      name,
      uuid,
      floor
    } = store.getState().ScreenshotsCanvasThreeD.present;
    data = {
      background,
      texts,
      devices,
      images,
      camera,
      dimensions,
      checkpoints,
      name,
      uuid,
      floor
    };
    thumbnail = store.getState().ScreenshotsCanvasThreeD.present.renderer.domElement.toDataURL()
  }
  return {data, thumbnail};
}

export function getCurrentStyleData() {
  let data = {
    ...store.getState().CanvasSlice.present,
    _persist: null,
    stageRef: null,
  };
  return data;
}

export function getStyleIndex(uuid) {
  let savedStyles = store.getState().SavedStyles.styles;
  let styleIndex;
  savedStyles.forEach((item, index) => {
    if (item.uuid === uuid) {
      styleIndex = index;
    }
  });

  return styleIndex;
}

export function isEquivalent(a, b) {
  // Create arrays of property names
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length != bProps.length) {
      return false;
  }

  for (var i = 0; i < aProps.length; i++) {
      var propName = aProps[i];

      // If values of same property are not equal,
      // objects are not equivalent
      if (a[propName] !== b[propName]) {
          return false;
      }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
}

export async function updateCanvasDimensions(dimensions){
  let stage_parent = window.getComputedStyle(document.getElementById("stage-parent"), null);
  let stage_parent_width = parseFloat(stage_parent.getPropertyValue('width'))
  let stage_parent_height = parseFloat(stage_parent.getPropertyValue('height'))
  let image_ratio = dimensions.width/dimensions.height
  let new_height = stage_parent_width * (dimensions.height/dimensions.width)
  let new_width = stage_parent_height * (dimensions.width/dimensions.height)

  if(dimensions.width >= dimensions.height){
      if(new_height > stage_parent_height){
          return {width: stage_parent_height * image_ratio, height: stage_parent_height}
      }
      else {
          return {width: stage_parent_width, height: new_height}
      }
  }
  else{
      if(new_width > stage_parent_width){
          return {width: stage_parent_width, height: stage_parent_width / image_ratio}
      }
      else {
          return {width: new_width, height: stage_parent_height}
      }
  }
}

export function usePrevious(value) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export function loadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new window.Image();
    img.addEventListener("load", () => resolve(img));
    img.addEventListener("error", err => reject(err));
    img.src = src;
  });
};

export async function getNewImage(url){
    let stringList = url.split('/')
    let response = await API.findCollectionImage(stringList[stringList.length - 1])
    let newUrl = await Firebase.getImageFromStorage(response.path)
    return newUrl
}

const ffmpeg = createFFmpeg({log: true});
export async function getFramesFromVideo(video, callback, toastId){
  toastId.current = toast.warn('📽 Video upload in progress', {
    progress: 0,
    autoClose: false
  });

  if(!ffmpeg.isLoaded()){
    await ffmpeg.load()
  }
  let media = await fetchFile(video);
  ffmpeg.FS('writeFile', 'video', media);
  await ffmpeg.run('-i', 'video', '-vf', 'fps=fps=30', '%d.png');
  ffmpeg.FS('unlink', 'video')

  let frames = []
  let items = ffmpeg.FS('readdir', '/').filter((p) => p.endsWith('.png'))
  for(let i=0; i < items.length; i++){
    let frame = ffmpeg.FS('readFile', items[i])
    let img = await loadImage(URL.createObjectURL(
      new Blob([frame.buffer], { type: "image/png" })
    ))

    frames.push(img)
    ffmpeg.FS('unlink', items[i])

    let progress = (i+1)/items.length
      toast.update(toastId.current, {
        progress: progress
      })

  }
  let duration = items.length / 30
  
  // frame change
  let fc = duration / items.length
  callback({frames, fc})
  toast.done(toastId.current);
  return frames[0]
}

export async function fetchFontFamily(family){
  let response = await fetch('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDqZriFjXj28L5mKnwtU5DBGkmT6t25MuU')
  let responseJson = await response.json() 
  for(let i=0; i<responseJson.items.length; i++){
      if(family === responseJson.items[i].family){
        return responseJson.items[i]
      } 
  }
  return null
}

export async function getImageDimensions(url){
  return new Promise((resolve, reject) => {
    var img = new Image();

    img.onload = function(){
      var height = img.naturalHeight;
      var width = img.naturalWidth;
      resolve({width, height})
    }
  
    img.src = url;
  })
}

export function closest(needle, haystack) {
  return haystack.reduce(function(prev, curr) {
    return (Math.abs(curr - needle) < Math.abs(prev - needle) ? curr : prev);
  });
}

export function degrees_to_radians(degrees) {
  var pi = Math.PI;
  return degrees * (pi / 180);
}

export function radians_to_degrees(radians) {
  var pi = Math.PI;
  return radians / (pi / 180);
}

export function userLoggedIn(){
  let user = store.getState().UserSlice.user
  if(user){
    return true
  }
  store.dispatch(setLoginModal(true))
  return false
}