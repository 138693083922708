import React from 'react';
import useImage from 'use-image';
import { store } from '../../../../../configureStore';


export function getLargestZedIndex(data){
  data = data || store.getState().CanvasSlice.present
  let combinedData = [...data.texts, ...data.devices, ...data.images]
  let largestZIndex = Math.max(...combinedData.map(o => o.zedIndex && Number.isInteger(o.zedIndex) ? o.zedIndex : 0), 0);
  return largestZIndex
}

export function usePrevious(value) {
    const ref = React.useRef();
    React.useEffect(() => {
      ref.current = value;
    });
    return ref.current;
}

// Get correct rotation for Konva rotation

export function getCorrectRotation(new_value){
    if(new_value < 0){
      while(new_value < 0){
        new_value += 360
      }
    }
    else if(new_value > 360){
      while(new_value > 360){
        new_value -= 360
      }
    }
    else if(new_value == 360){
      new_value = 0
    }
    return new_value
}

// Create an img element to use in Konva

export function loadImage(src) {
    return new Promise((resolve, reject) => {
      const img = new window.Image();
      img.addEventListener("load", () => resolve(img));
      img.addEventListener("error", err => reject(err));
      img.crossOrigin = 'Anonymous';
      img.src = src;
    });
};

export function loadVideo(src){
  return new Promise((resolve, reject) => {
  let video = document.createElement("video");
  video.setAttribute('crossorigin', 'anonymous');
  video.addEventListener("loadeddata", () => resolve(video));
  video.addEventListener("error", err => reject(err));
  video.src = src;
  })
}

export function getThumbnailFromVideo(video){
  return new Promise((resolve, reject) => {
  video.addEventListener('seeked', function() {
  let canvas = document.createElement("canvas");
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  let ctx = canvas.getContext("2d");
  ctx.drawImage(video, 0, 0,  canvas.width, canvas.height);
  let thumbnail = canvas.toDataURL()
  resolve(thumbnail) 
  })
  video.addEventListener("error", err => reject(err));
  video.currentTime = 0.1
})
}


export async function updateCanvasDimensions(dimensions){
  let stage_parent = window.getComputedStyle(document.getElementById("stage-parent"), null);
  let stage_parent_width = parseFloat(stage_parent.getPropertyValue('width'))
  let stage_parent_height = parseFloat(stage_parent.getPropertyValue('height'))
  let image_ratio = dimensions.width/dimensions.height
  let new_height = stage_parent_width * (dimensions.height/dimensions.width)
  let new_width = stage_parent_height * (dimensions.width/dimensions.height)

  if(dimensions.width >= dimensions.height){
      if(new_height > stage_parent_height){
          return {width: stage_parent_height * image_ratio, height: stage_parent_height}
      }
      else {
          return {width: stage_parent_width, height: new_height}
      }
  }
  else{
      if(new_width > stage_parent_width){
          return {width: stage_parent_width, height: stage_parent_width / image_ratio}
      }
      else {
          return {width: new_width, height: stage_parent_height}
      }
  }
}

      // were can we snap our objects?
      function getLineGuideStops(skipShape, stage, numOfScreenshots=10, scaledScreenshotWidth, scaledGapWidth) {

        let vertical_stops = []
        for(let i=0; i < numOfScreenshots; i++){
          vertical_stops.push((scaledScreenshotWidth+scaledGapWidth)*i + scaledScreenshotWidth/2)
        }
        
        // we can snap to stage borders and the center of the stage
        var vertical = [0, ...vertical_stops, stage.width()];
        var horizontal = [0, stage.height() / 2, stage.height()];
  
        // and we snap over edges and center of each object on the canvas
        stage.find('.object').forEach(guideItem => {
          if (guideItem === skipShape) {
            return;
          }

          if(guideItem.attrs.id && guideItem.attrs.id.includes('device')){
            var box = guideItem.children[0].getClientRect({skipShadow: true})
          }
          else{
            var box = guideItem.getClientRect();
          }
          // and we can snap to all edges of shapes
          vertical.push([box.x, box.x + box.width, box.x + box.width / 2]);
          horizontal.push([box.y, box.y + box.height, box.y + box.height / 2]);
        });
        return {
          vertical: vertical.flat(),
          horizontal: horizontal.flat()
        };
      }

      function toRadians (angle) {
        return angle * (Math.PI / 180);
      }
    
      // what points of the object will trigger to snapping?
      // it can be just center of the object
      // but we will enable all edges and center
      function getObjectSnappingEdges(node, scale) {
        if(node.attrs.id && node.attrs.id.includes('device')){
          var box = node.children[0].getClientRect({skipShadow: true})
        }
        else{
          var box = node.getClientRect({skipShadow: true});
        }
        // let actual_width = node.width() * node.attrs.scaleX
        // let actual_height = node.height() * node.attrs.scaleY

        // let angle = toRadians(node.attrs.rotation)

        return {
          vertical: [
            {
              guide: Math.round(box.x),
              offset: Math.round(node.x() - box.x/scale),
              snap: 'start'
            },
            {
              guide: Math.round(box.x + box.width / 2),
              offset: Math.round(node.x() - box.x/scale - box.width / (2 * scale) ),
              snap: 'center'
            },
            {
              guide: Math.round(box.x + box.width),
              offset: Math.round(node.x() - box.x/scale - box.width/scale),
              snap: 'end'
            }
          ],
          horizontal: [
            {
              guide: Math.round(box.y),
              offset: Math.round(node.y() - box.y/scale),
              snap: 'start'
            },
            {
              guide: Math.round(box.y + box.height / 2),
              offset: Math.round(node.y() - box.y/scale - box.height / (2 * scale)),
              snap: 'center'
            },
            {
              guide: Math.round(box.y + box.height),
              offset: Math.round(node.y() - box.y/scale - box.height/scale),
              snap: 'end'
            }
          ]
        };
      }
    
      // find all snapping possibilities
      function getGuides(lineGuideStops, itemBounds, scale) {

        var GUIDELINE_OFFSET = 20*scale;

        var resultV = [];
        var resultH = [];
    
        lineGuideStops.vertical.forEach(lineGuide => {
          itemBounds.vertical.forEach(itemBound => {
            var diff = Math.abs(lineGuide - itemBound.guide);
            // if the distance between guild line and object snap point is close we can consider this for snapping
            if (diff < GUIDELINE_OFFSET) {
              resultV.push({
                lineGuide: lineGuide,
                diff: diff,
                snap: itemBound.snap,
                offset: itemBound.offset
              });
            }
          });
        });
    
        lineGuideStops.horizontal.forEach(lineGuide => {
          itemBounds.horizontal.forEach(itemBound => {
            var diff = Math.abs(lineGuide - itemBound.guide);
            if (diff < GUIDELINE_OFFSET) {
              resultH.push({
                lineGuide: lineGuide,
                diff: diff,
                snap: itemBound.snap,
                offset: itemBound.offset
              });
            }
          });
        });
    
        var guides = [];
    
        // find closest snap
        var minV = resultV.sort((a, b) => a.diff - b.diff)[0];
        var minH = resultH.sort((a, b) => a.diff - b.diff)[0];

        if (minV) {
          guides.push({
            lineGuide: minV.lineGuide / scale,
            offset: minV.offset,
            orientation: 'V',
            snap: minV.snap
          });
        }
        if (minH) {
          guides.push({
            lineGuide: minH.lineGuide / scale,
            offset: minH.offset,
            orientation: 'H',
            snap: minH.snap
          });
        }
        return guides;
      }
    
      function drawGuides(guides, layer, dimensions) {
        let width = layer.parent.attrs.width/layer.parent.attrs.scaleY
        let height = layer.parent.attrs.height/layer.parent.attrs.scaleX

        // let strokeParam = Math.min(dimensions.height, dimensions.width)
        guides.forEach(lg => {
          if (lg.orientation === 'H') {
            var line = new Konva.Line({
              points: [-width, lg.lineGuide, width, lg.lineGuide],
              stroke: 'rgb(233, 30, 99)',
              strokeWidth: dimensions.height/500,
              name: 'guid-line',
              // dash: [strokeParam/200, strokeParam/1000],

            });
            layer.add(line);
            layer.batchDraw();
          } else if (lg.orientation === 'V') {
            var line = new Konva.Line({
              points: [lg.lineGuide, -height, lg.lineGuide, height],
              stroke: 'rgb(233, 30, 99)',
              strokeWidth: dimensions.width/500,
              name: 'guid-line',
              // dash: [strokeParam/200, strokeParam/1000],
            });
            layer.add(line);
            layer.batchDraw();
          }
        });
      }
    
      
      export const onDragEndGuideLines = (e, layer) => {

        // clear all previous lines on the screen
        layer.find('.guid-line').destroy();
        layer.batchDraw();
      }
    
      export const onDragMoveGuideLines = (e, stage, layer, scale, numberOfScreenshots, scaledScreenshotWidth, scaledGapWidth, dimensions) => {
        if(e.target.getClassName() === 'Transformer') return
        // clear all previous lines on the screen
        layer.find('.guid-line').destroy();
    
        // find possible snapping lines
        var lineGuideStops = getLineGuideStops(e.target, stage,numberOfScreenshots, scaledScreenshotWidth, scaledGapWidth);

        // find snapping points of current object
        var itemBounds = getObjectSnappingEdges(e.target, scale);
    
        // now find where can we snap current object
        var guides = getGuides(lineGuideStops, itemBounds, scale);
    
        // do nothing of no snapping
        if (!guides.length) {
          return;
        }
    
        drawGuides(guides, layer, dimensions);
    
        // now force object position

        guides.forEach(lg => {
          switch (lg.snap) {
            case 'start': {
              switch (lg.orientation) {
                case 'V': {
                  e.target.x(lg.lineGuide + lg.offset);
                  break;
                }
                case 'H': {
                  e.target.y(lg.lineGuide + lg.offset);
                  break;
                }
              }
              break;
            }
            case 'center': {
              switch (lg.orientation) {
                case 'V': {
                  e.target.x(lg.lineGuide + lg.offset);
                  break;
                }
                case 'H': {
                  e.target.y(lg.lineGuide + lg.offset);
                  break;
                }
              }
              break;
            }
            case 'end': {
              switch (lg.orientation) {
                case 'V': {
                  e.target.x(lg.lineGuide + lg.offset);
                  break;
                }
                case 'H': {
                  e.target.y(lg.lineGuide + lg.offset);
                  break;
                }
              }
              break;
            }
          }
        });
      }


      export const getLinearGradientCoordinates = (deg=180, canvas_dimensions) => {
        let [x, y] = [0, 0]
          if(deg >=45 && deg <=135){
            let percent = (deg-45)/90
            let height = canvas_dimensions.height*(1-percent)
            y = height
          }
          else if(deg >= 225 && deg <= 315){
            let percent = (deg-225)/90
            let height = canvas_dimensions.height*percent
            y = height
            x = canvas_dimensions.width
          }
          else if(deg > 135 && deg < 225){
            let percent = (deg-135)/90
            let width = canvas_dimensions.width*percent
            x = width
          }
          else if(deg < 45){
            let percent = deg/90
            let width = canvas_dimensions.width*(0.5 - percent)
            x = width
            y = canvas_dimensions.height
          }
          else if(deg > 315 && deg <= 360){
            let percent = (deg-315)/90
            let width = canvas_dimensions.width*(1- percent)
            x = width
            y = canvas_dimensions.height
          }
          return {
            start: {
              x: x,
              y: y
            },
            end: {
              x: canvas_dimensions.width - x,
              y: canvas_dimensions.height - y,
            }
          }
      }

      export const getLinearGradientStops = (gradient) => {
        let [percent1, percent2, color1, color2] = [gradient.percent1, gradient.percent2,gradient.color1, gradient.color2]
  
        if(percent1 > percent2){
            percent2 = percent1
        }
        return [percent1/100, color1, percent2/100, color2]
      }
      export const getImageScale = (src, canvas_dimensions) => {
        var x_scale = 1
        var y_scale = 1
        const [image] = useImage(src, 'Anonymous');

  
        if([image][0] != undefined){
            x_scale =  canvas_dimensions.width/[image][0].naturalWidth
            y_scale = canvas_dimensions.height/[image][0].naturalHeight
        }
  
        return {
          image: image,
          x_scale: x_scale,
          y_scale: y_scale
        }
      }

      export function calculateTotalWidth(meta){
          return (meta.dimensions.width * meta.numOfScreenshots) + ((meta.numOfScreenshots - 1) * meta.gapWidth)  // (Screenshot width * number of screenshots) + (number of gaps * gap width)
      }

      export function createEditableText(textNode, tr, onChange) {

        // hide text node and transformer:
        let layer = textNode.parent
        let stage = layer.parent
        let stageScale = stage.attrs.scaleX
        let textScale = textNode.attrs.scaleX
        let combinedScale = stageScale * textScale
        textNode.hide();
        tr.hide();
        layer.draw();

        // create textarea over canvas with absolute position
        // first we need to find position for textarea
        // how to find it?

        // at first lets find position of text node relative to the stage:
        var textPosition = textNode.absolutePosition();

        // then lets find position of stage container on the page:
        var stageBox = stage.container().getBoundingClientRect();

        // so position of textarea will be the sum of positions above:
        var areaPosition = {
          x: stageBox.left + textPosition.x,
          y: stageBox.top + textPosition.y,
        };

        // create textarea and style it
        var editableDiv = document.createElement('div');
        editableDiv.setAttribute('contenteditable', 'true')
        editableDiv.setAttribute('spellcheck', 'false')
        let mainDiv = document.getElementById('main-div-id')
        mainDiv.appendChild(editableDiv);

        // apply many styles to match text on canvas as close as possible
        // remember that text rendering on canvas and on the textarea can be different
        // and sometimes it is hard to make it 100% the same. But we will try...
        editableDiv.innerText = textNode.text();
        editableDiv.style.position = 'absolute';
        editableDiv.style.top = areaPosition.y + 'px';
        editableDiv.style.left = areaPosition.x + 'px';
        editableDiv.style.width = 'auto';
        editableDiv.style.height = 'auto';
        editableDiv.style.fontSize = textNode.fontSize() + 'px';
        editableDiv.style.border = 'none';
        editableDiv.style.padding = '0px';
        editableDiv.style.margin = '0px';
        editableDiv.style.background = 'none';
        editableDiv.style.outline = 'none';
        editableDiv.style.resize = 'none';
        editableDiv.style.lineHeight = textNode.lineHeight();
        editableDiv.style.letterSpacing = textNode.letterSpacing() + 'px';
        editableDiv.style.fontWeight = textNode.fontStyle().replace(/\D/g,'');
        editableDiv.style.fontStyle = textNode.fontStyle().replace(/[^a-zA-Z]+/g, '');
        editableDiv.style.fontFamily = textNode.fontFamily();
        editableDiv.style.transformOrigin = 'left top';
        editableDiv.style.textAlign = textNode.align();
        editableDiv.style.color = textNode.fill();
        let rotation = textNode.rotation();
        var transform = '';
        if (rotation) {
          transform += 'rotateZ(' + rotation + 'deg)';
        }

        var px = 0;
        // also we need to slightly move editableDiv on firefox
        // because it jumps a bit
        var isFirefox =
          navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        if (true) {
          px += 2 * combinedScale;
        }
        transform += 'translateY(-' + px + 'px)';
        transform += `scale(${combinedScale})`;

        editableDiv.style.transform = transform;

        editableDiv.focus();

        editableDiv.addEventListener('keydown', function (e) {
          if (e.key === 'Enter') {
            document.execCommand('insertLineBreak')
            e.preventDefault()
          }
        });

        function removeTextarea() {
          editableDiv.parentNode.removeChild(editableDiv);
          window.removeEventListener('click', handleOutsideClick);
          textNode.show();
          tr.show();
          // tr.forceUpdate();
          layer.draw();
        }

        function handleOutsideClick(e) {
          if (e.target !== editableDiv) {
            onChange(editableDiv.innerText.toString().replace(/^\s+|\s+$/g, ''));
            removeTextarea();
          }
        }
        function removeTextareaOnResize(){
          window.removeEventListener("resize", removeTextareaOnResize)
          if(editableDiv.parentNode !== null){
            removeTextarea()
          }

        }
        setTimeout(() => {
          window.addEventListener('click', handleOutsideClick);
          window.addEventListener("resize", removeTextareaOnResize);
        });
      }

      export function dragElement(elmnt) {
        var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
        if (document.getElementById(elmnt.id + "header")) {
          // if present, the header is where you move the DIV from:
          document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
        } else {
          // otherwise, move the DIV from anywhere inside the DIV:
          elmnt.onmousedown = dragMouseDown;
        }
      
        function dragMouseDown(e) {
          e = e || window.event;
          e.preventDefault();
          // get the mouse cursor position at startup:
          pos3 = e.clientX;
          pos4 = e.clientY;
          document.onmouseup = closeDragElement;
          // call a function whenever the cursor moves:
          document.onmousemove = elementDrag;
        }
      
        function elementDrag(e) {
          e = e || window.event;
          e.preventDefault();
          // calculate the new cursor position:
          pos1 = pos3 - e.clientX;
          pos2 = pos4 - e.clientY;
          pos3 = e.clientX;
          pos4 = e.clientY;
          // set the element's new position:
          elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
          elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
        }
      
        function closeDragElement() {
          // stop moving when mouse button is released:
          document.onmouseup = null;
          document.onmousemove = null;
        }
      }